import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Link, animateScroll as scroll } from "react-scroll"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Linechart from "../AllCharts/chartist/dountchart"

import { Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchSalesAllData } from "../../store/salesAll/actions"
import Barchart from "../AllCharts/chartist/barchart"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

Barchart.defaultProps = {
  labels: [],
  data: [],
}

const SalesAll = () => {
  const salesData = useSelector(state => state.salesAllReducer.salesData)
  const isLoading = useSelector(state => state.salesAllReducer.loading)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchSalesAllData())
  }, [dispatch])

  const styles = {
    odd: { backgroundColor: "#f2f2f2" },
    even: { backgroundColor: "#ddd" },
  }

  const [displayItems, setDisplayItems] = useState({})

  useEffect(() => {
    if (salesData) {
      let initialDisplayItems = {}
      salesData.forEach(monthData => {
        initialDisplayItems[monthData.month] = 10
      })
      setDisplayItems(initialDisplayItems)
    }
  }, [salesData])

  const handleShowMore = month => {
    setDisplayItems(prevState => ({
      ...prevState,
      [month]: prevState[month] + 10,
    }))
  }

  const handleRowClick = (month, ipcNo) => {
    // `navigate`関数を使用してクエリパラメータ付きのURLに遷移
    navigate(`/sales?month=${month}&loc_id=${ipcNo}`)
  }

  const [showPercentageCount, setShowPercentageCount] = useState(false)
  const [showPercentageAmount, setShowPercentageAmount] = useState(false)

  const handleClickCountGraph = () => {
    setShowPercentageCount(prev => !prev)
  }

  const handleClickAmountGraph = () => {
    setShowPercentageAmount(prev => !prev)
  }

  const convertToCSV = data => {
    // CSVのヘッダー
    const headers = ["月", "ランキング", "商品名", "価格", "数量", "商品別売上"]

    // データ行
    const rows = data.flatMap(item => {
      // 商品を売上でソートし、順位を付ける
      const sortedGoods = item.goodsSale.sort((a, b) => b.sales - a.sales)
      return sortedGoods.map((goods, index) =>
        [
          item.month,
          index + 1, // 順位
          `"${goods.name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          goods.price,
          goods.quantity,
          goods.sales,
        ].join(",")
      )
    })

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const convertlocationSaleToCSV = data => {
    // CSVのヘッダー
    const headers = ["月", "ランキング", "ロケーション名", "販売個数", "売上"]

    // データ行
    const rows = data.flatMap(item => {
      // ロケーションを売上でソートし、順位を付ける
      const sortedLocations = item.locationSale.sort(
        (a, b) => b.sales - a.sales
      )
      return sortedLocations.map((location, index) =>
        [
          item.month,
          index + 1, // 順位
          location.location,
          location.quantity,
          location.sales,
        ].join(",")
      )
    })

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const convertgoodsSalePerIpcToCSV = data => {
    // CSVのヘッダー
    const headers = [
      "月",
      "ランキング",
      "商品名",
      "単価",
      "数量（台数で割る）",
      "売上（台数で割る）",
    ]

    // データ行
    const rows = data.flatMap(item => {
      // goodsSalePerIpcを売上でソートし、順位を付ける
      const sortedGoods = item.goodsSalePerIpc.sort(
        (a, b) => b.sales - a.sales
      )
      return sortedGoods.map((goods, index) =>
        [
          item.month,
          index + 1, // 順位
          `"${goods.name}"`, // 商品名にカンマがある場合のためにダブルクォートで囲む
          goods.price,
          goods.quantity,
          goods.sales,
        ].join(",")
      )
    })

    // 全てを結合
    return "\uFEFF" + [headers.join(","), ...rows].join("\n")
  }

  const downloadCSV = (data, convertFunction, filename) => {
    const csvString = convertFunction(data)
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // 商品別ランキングのダウンロード
  const downloadProductRankingCSV = () => {
    // displayItemsから月の配列を取得
    const months = Object.keys(displayItems).sort()

    // 最初と最後の月を取得
    const firstMonth = months[0]
    const lastMonth = months[months.length - 1]

    // ファイル名に期間を追加
    const filename = `salesAll_product_ranking_${firstMonth}_${lastMonth}.csv`
    downloadCSV(salesData, convertToCSV, filename)
  }

  // ロケーション別ランキングのダウンロード
  const downloadLocationRankingCSV = () => {
    // displayItemsから月の配列を取得
    const months = Object.keys(displayItems).sort()

    // 最初と最後の月を取得
    const firstMonth = months[0]
    const lastMonth = months[months.length - 1]

    // ファイル名に期間を追加
    const filename = `salesAll_location_ranking_${firstMonth}_${lastMonth}.csv`
    downloadCSV(salesData, convertlocationSaleToCSV, filename)
  }

  // 自販機毎商品別売上ランキング（台数で割る）のダウンロード
  const downloadgoodsSalePerIpcCSV = () => {
    // displayItemsから月の配列を取得
    const months = Object.keys(displayItems).sort()

    // 最初と最後の月を取得
    const firstMonth = months[0]
    const lastMonth = months[months.length - 1]

    // ファイル名に期間を追加
    const filename = `salesAll_goodsSalePerIpc_ranking_${firstMonth}_${lastMonth}.csv`
    downloadCSV(salesData, convertgoodsSalePerIpcToCSV, filename)
  }

  const tableStyle = window.innerWidth < 500 ? "p-0" : "mx-auto p-3"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="ホーム"
            breadcrumbItem="売上分析（ロケーション全体）"
          />
          <ErrorMessage />
          <div>
            <Col xs={12} xl={12}>
              <LoadingOverlay isLoading={isLoading} />
              {salesData &&
                salesData.map((monthData, index) => (
                  <Button
                    key={index}
                    tag={Link}
                    to={monthData.month}
                    smooth={true}
                    duration={500}
                    className="mr-2 mb-2 my-custom-button"
                  >
                    {monthData.month}
                  </Button>
                ))}
            </Col>

            <Col xs={12} xl={12} className="d-flex">
              <Button
                color="info"
                onClick={downloadProductRankingCSV}
                className="mr-2 mb-2 my-custom-button"
              >
                商品別売上CSV
              </Button>
              <Button
                color="info"
                onClick={downloadgoodsSalePerIpcCSV}
                className="mr-2 mb-2 my-custom-button"
              >
                商品別売上（1台あたり）CSV
              </Button>
              <Button
                color="info"
                onClick={downloadLocationRankingCSV}
                className="mr-2 mb-2 my-custom-button"
              >
                ロケーション別CSV
              </Button>
            </Col>
          </div>
          {salesData &&
            salesData.map((monthData, index) => (
              <Row key={index} id={monthData.month}>
                <Col xs={12}>
                  <div className="lineWithText">{monthData.month}</div>
                  <Card className={tableStyle} style={{ maxWidth: "1200px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 売上</h5>
                      </CardTitle>
                      <Barchart
                        labels={monthData.labels}
                        data={monthData.sales}
                        label={monthData.label}
                      />
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 商品別売上ランキング</h5>
                      </CardTitle>
                      <Table>
                        <thead>
                          <tr>
                            <th className="px-2 text-end">順位</th>
                            <th className="product-name">商品名</th>
                            <th className="sales-price">単価</th>
                            <th className="sales-count">個数</th>
                            <th className="sales-amount">売上</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthData.goodsSale
                            .slice(0, displayItems[monthData.month])
                            .map((item, index) => (
                              <tr
                                key={index}
                                style={
                                  index % 2 === 0 ? styles.even : styles.odd
                                }
                              >
                                <td className="text-end">{index + 1}</td>
                                <td className="product-name">{item.name}</td>
                                <td className="sales-price">
                                  {item.price.toLocaleString()}
                                </td>
                                <td className="sales-count">{item.quantity}</td>
                                <td className="sales-amount">
                                  {item.sales.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={3}>Total</td>
                            <td className="sales-count">
                              {monthData.goodsSale.reduce(
                                (sum, item) => sum + item.quantity,
                                0
                              )}
                            </td>
                            <td className="sales-amount">
                              {monthData.goodsSale
                                .reduce((sum, item) => sum + item.sales, 0)
                                .toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {displayItems[monthData.month] <
                        monthData.goodsSale.length && (
                        <Button onClick={() => handleShowMore(monthData.month)}>
                          続きを表示
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} ロケーション別売上ランキング</h5>
                      </CardTitle>
                      <Table>
                        <thead>
                          <tr>
                            <th className="px-2 text-end">順位</th>
                            <th className="product-name">ロケーション名</th>
                            <th className="sales-count">個数</th>
                            <th className="sales-amount">売上</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthData.locationSale
                            .slice(0, displayItems[monthData.month])
                            .map((item, index) => (
                              <tr
                                key={index}
                                onClick={() =>
                                  handleRowClick(monthData.month, item.ipc_no)
                                }
                              >
                                <td className="text-end">{index + 1}</td>
                                <td classNeme="product-name">
                                  {item.location}
                                </td>
                                <td className="sales-count">{item.quantity}</td>
                                <td className="sales-amount">
                                  {item.sales.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td className="sales-count">
                              {monthData.locationSale.reduce(
                                (sum, item) => sum + item.quantity,
                                0
                              )}
                            </td>
                            <td className="sales-amount">
                              {monthData.locationSale
                                .reduce((sum, item) => sum + item.sales, 0)
                                .toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {displayItems[monthData.month] <
                        monthData.locationSale.length && (
                        <Button onClick={() => handleShowMore(monthData.month)}>
                          続きを表示
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 商品（ロケ）別売上ランキング</h5>
                      </CardTitle>
                      <Table className="">
                        <thead>
                          <tr>
                            <th className="px-2 text-end">順位</th>
                            <th className="product-name">商品名</th>
                            <th className="sales-price">単価</th>
                            <th className="sales-count">個数</th>
                            <th className="sales-amount">売上</th>
                          </tr>
                        </thead>
                        <tbody>
                          {monthData.goodsSalePerIpc
                            .slice(0, displayItems[monthData.month])
                            .map((item, index) => (
                              <tr
                                key={index}
                                style={
                                  index % 2 === 0 ? styles.even : styles.odd
                                }
                              >
                                <td className="text-end">{index + 1}</td>
                                <td className="product-name">
                                  {item.name}({item.loc_name})
                                </td>
                                <td className="sales-price">
                                  {item.price.toLocaleString()}
                                </td>
                                <td className="sales-count">{item.quantity}</td>
                                <td className="sales-amount">
                                  {item.sales.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      {displayItems[monthData.month] <
                        monthData.goodsSalePerIpc.length && (
                        <Button onClick={() => handleShowMore(monthData.month)}>
                          続きを表示
                        </Button>
                      )}
                    </CardBody>
                  </Card>
                  <Card className={tableStyle} style={{ maxWidth: "750px" }}>
                    <CardBody>
                      <CardTitle>
                        <h5>{monthData.month} 支払い方法別売上</h5>
                      </CardTitle>

                      {/* 売上個数のグラフ */}
                      <CardTitle>
                        <h6>売上個数</h6>
                      </CardTitle>
                      <Linechart
                        onClick={handleClickCountGraph}
                        labels={monthData.payTypes.map(pt => pt.type)}
                        data={
                          showPercentageCount
                            ? monthData.payTypes.map(pt => pt.ratio)
                            : monthData.payTypes.map(pt => pt.count)
                        }
                        showPercentage={showPercentageCount}
                      />

                      {/* 売上金額のグラフ */}
                      <CardTitle>
                        <h6>売上金額</h6>
                      </CardTitle>
                      <Linechart
                        onClick={handleClickAmountGraph}
                        labels={monthData.payAmountTypes.map(pt => pt.type)}
                        data={
                          showPercentageAmount
                            ? monthData.payAmountTypes.map(pt => pt.ratio)
                            : monthData.payAmountTypes.map(pt => pt.amount)
                        }
                        showPercentage={showPercentageAmount}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ))}
        </Container>
        <div
          style={{
            position: "fixed",
            bottom: "1em",
            right: "1em",
            opacity: "0.7",
          }}
        >
          <Button onClick={() => scroll.scrollToTop()}>TOPへ戻る</Button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SalesAll
